
/**
 * @roxi/routify 2.18.4
 * File generated Sun Apr 03 2022 06:57:41 GMT-0700 (Pacific Daylight Time)
 */

export const __version = "2.18.4"
export const __timestamp = "2022-04-03T13:57:41.379Z"

//buildRoutes
import { buildClientTree } from "@roxi/routify/runtime/buildRoutes"

//imports
import _index from '../src/pages/index.svelte'

//options
export const options = {}

//tree
export const _tree = {
  "root": true,
  "children": [
    {
      "isIndex": true,
      "isPage": true,
      "path": "/index",
      "id": "_index",
      "component": () => _index
    }
  ],
  "path": "/"
}


export const {tree, routes} = buildClientTree(_tree)

