import "./app.css";
import "./ubuntu.css";
import "./ubuntu_mono.css";
import "./roboto.css";
import App from "./App.svelte";

const app = new App({
  target: document.body,
  props: {
  },
});

export default app;
